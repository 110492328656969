.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.error-text {
  font-size: 14px;
  color: red;
}

.client-table-row {
  cursor: pointer;
}
/* .client-table-row:hover {
  background-color: #d2e1fc;
  cursor: pointer;
  font-weight: bold;
  box-shadow: 0 6px 8px 2px rgba(0, 0, 0, 0.2);
} */
.client-table-head {
  font-size: 14px !important;
  font-weight: bold !important
}


.level1{
  padding-left: 30px !important;
}

.level2 {
  padding-left: 70px !important;
}

.level3 {
  padding-left: 110px !important;
}

.level4 {
  padding-left: 120px !important;
}

.user-permission-add-btn {
  width: 20px;
  height: 20px;
  min-width: 20px !important;
}

.search-box {
  background-color: azure;
  width: 300px;
  border-radius: 4px;
  background-color: white;
  border: 1px solid #ced4da !important;
  padding: 4px 10px 0px 10px !important;
  margin-bottom:5px;
  font-family:'Space Grotesk';
  font-size: 12px !important;
  height: 36px
}

.search-box input {
  width: 280px;
  min-width: 280px !important;
}

.css-c6bojz {
  font-size: 12px !important;
  min-width: 150px !important;
}

.MuiInputBase-input {
  font-size: 13px !important;
  min-width: 170px !important;
}

.css-1le28he-MuiInputBase-root {
  width: 100% !important;
}
.MuiIconButton-root:hover {
  background-color: rgba(255 255 255 / 81%) !important;
}
.signup-btn {
  background-color: #1976d2 !important;
  margin-top: 24px !important;
  margin-bottom: 16px !important;
  padding: 6px 16px !important;
  color: #ffff !important;
}
.gv-unauthorized {
  display: flex;
  align-items: center;
  text-align: center;
  height: 300px;
}
.LandingAppbar {
  z-index: 1000 !important;
}
.MuiDrawer-paperAnchorLeft {
  z-index: 1 !important;
}
.menu-active {
  background-color: #dde3eb !important;
}


.ge-margin-bottom-0 {
  margin-bottom: 0px !important;
}
.ge-margin-top-0 {
  margin-top: 0px !important;
}

.visibility-hidden{
  visibility: hidden;
}

.selected-file {
  padding: 0 6px;
  background: #efefef;
  border-radius: 4px;
}
.gv-landing-page {
  width: 100%;
  height: 100vh;
}

.gv-landing-image {
  position: absolute;
  bottom: 30px;
  width: 450px;
  padding-left: 20px;
}

.gv-landing-left {
  position: fixed;
  bottom: 0;
  left: 0;
  height: 100%;
  z-index: -1;
  width: 650px;
}

.gv-landing-right {
  position: fixed;
  bottom: 0;
  right: -300px;
  height: 100%;
  z-index: -1;
    
}

.landing-form {
  margin-top: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: rgba(255,255,255,0.9);
  border-radius: 10px
}

.landing-form input {
  background-color: white;
}

.custom-input-style {
  padding: 12px !important;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;
  margin-bottom: 6px;
}

.position-relative {
  position: relative !important;
}

.password-eye-icon {
  position: absolute !important;
  right: 0;
  margin-top: 8px !important;
  margin-right: 12px !important;
}

.calibration-report-container {
  max-width:1170px;
  width:100%;
  margin:0 auto;
  padding: 0 15px;
  font-family: 'Roboto', sans-serif;
}
.site-visit-report-container {
  max-width:1170px;
  width:100%;
  margin:0 auto;
  padding: 0 15px;
  font-family: 'Roboto', sans-serif;
}

.calibration-data {
  width:100%;
}

.calibartion-table-container {
  border: 1px solid #ccc;
  border-collapse: collapse;
  margin: 0;
  padding: 0;
  width: 100%;
  table-layout: fixed;
  font-family: 'Roboto', sans-serif;
}

@media print {
  .calibartion-table-container {
    border: none !important;
  }
}

.calibartion-table-head {
  font-size: .85em;
  letter-spacing: .1em;
  text-transform: capitalize;
  font-weight:500;
}

.calibartion-table-head {
  background-color: #64d001;
  color: #ffff !important;
  font-weight: 500;
  font-size: 20px;
}

.ct-table-head {
  color: #ffff !important;
  font-weight: 600 !important;
  font-size: 16px !important;
}

.ct-project-name {
  font-weight: 600 !important;
  font-size: 18px !important;
}

.ct-sensor-name{
  font-weight: 500 !important;
  font-size: 14px !important;
}

.text-capitalize {
  text-transform: capitalize;
}

.font-size-12 {
  font-size: 12px !important;
}

.font-size-14 {
  font-size: 14px !important;
}

.font-weight-600 {
  font-weight: 600 !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

.tickets-table-row {
  cursor: pointer;
}

.trigger-pdf-save {
  display: none
}

.slick-track {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 400px;
  width: 600px;
}
.slick-slide {
  width: 100% !important;
   box-sizing: border-box;
  
}
.slick-active {
  width: 560px !important;
}
.slider-image {
 padding: 0 40px;
 width: 500px;
 display: flex;
 margin-left: 23px;
}
.slider-container {
  max-width: 900px
}

.slick-next, .slick-prev{
  display: block; 
  width: 30px; 
  height: 30px; 
  position: absolute; 
  bottom: 50%;
  z-index: 100;
  cursor: pointer;
  border-radius: 10px;
}
.slick-prev:hover, .slick-next:hover {
  background-color: rgba(184, 183, 183, 0.8); 
}
.slick-list{
  padding: 0 !important;
}

/* Custom scroll */
.custom-scroll::-webkit-scrollbar-track {
  /* -webkit-box-shadow: inset 0 0 6px #bdbdbd; */
  border-radius: 10px;
  position: absolute;
}

.custom-scroll::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  /* background-color: inherit; */
}

.custom-scroll::-webkit-scrollbar-thumb {
  border-radius: 10px;
}

.custom-scroll:hover::-webkit-scrollbar-thumb {
  background-color: #bdbdbd;
  -webkit-box-shadow: inset 0 0 6px #bdbdbd;
}

.custom-scroll::-webkit-scrollbar-thumb:hover {
  background-color: #a0a0a5;
  border: 4px solid #a0a0a5;
}

.back-arrow-btn {
  cursor: pointer;
  padding: 0 4px 0 0;
}


@media only screen and (max-width: 375px) {
  .search-box {
    width: 270px;
  }
  
  .search-box input {
    width: 250px !important;
    min-width: 250px !important
  }
}

.red{background-color: #F9BF8F !important; color: inherit; border-color: inherit; border-style:solid; border-width:1px;}

.green{background-color: #c2d69b !important; color: inherit; border-color: inherit;}
.highlight{font-weight:bold;background-color:#c2d69b !important;border-color:inherit;text-align:center;vertical-align:middle}

@import './w3.css';
